import {
	Injectable,
	ComponentFactoryResolver,
	ViewContainerRef,
	ComponentRef,
	Type,
	signal,
	computed
} from '@angular/core';
import { MatDrawer, MatDrawerMode } from '@angular/material/sidenav';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class GlobalDrawerService {
	private _viewContainerRef: ViewContainerRef | null = null;
	private _closeSubject: Subject<any> | null = null; // Subject for close event

	mode = computed(() => {
		//!Bug; Enforces the 'over' state.
		this._opened();
		return 'over' as MatDrawerMode;
	});

	isOpen = computed(() => this._opened());
	private _opened = signal<boolean>(false);

	private _cleanUpRef: NodeJS.Timeout | null = null;

	registerView(viewContainerRef: ViewContainerRef): void {
		if (!!this._viewContainerRef) {
			console.error('Potential View Conflict - A container is already registered.');
		}
		this._viewContainerRef = viewContainerRef;
	}

	unregisterView(): void {
		this.close();
		this._viewContainerRef = null;
		this._closeSubject = null;
	}

	/**
	 * Opens the drawer with the given component and returns an observable
	 * that emits data when the drawer is closed.
	 */
	open<T = any, U extends object = any>(
		component: new (...args) => U,
		inputs?: Partial<{ [K in keyof U]: U[K] }>
	): Observable<T> {
		if (!!this._viewContainerRef) {
			this._clearView();
			const componentRef: ComponentRef<any> = this._viewContainerRef.createComponent(component);
			if (!!inputs) {
				Object.keys(inputs).forEach((key) => {
					componentRef.instance[key] = inputs[key];
				});
			}
			this._closeSubject = new Subject<any>();
			this._opened.set(true);
			return this._closeSubject.asObservable();
		} else {
			return new Observable((observer) => observer.complete());
		}
	}

	/**
	 * Closes the drawer and emits data (if any) to the observable.
	 */
	close<T = any>(data?: T) {
		this._opened.set(false);
		if (!!this._closeSubject) {
			this._closeSubject.next(data);
			this._closeSubject.complete();
		}
		this._closeSubject = null; //Reset the subject after closing
		this._clearView(true);
	}

	/**
	 * Toggles the drawer's visibility.
	 * @important This does not fully close the drawer.
	 */
	toggle(state?: boolean) {
		if (state !== undefined) {
			this._opened.set(state);
		} else {
			this._opened.update((v) => !v);
		}
	}

	private _clearView(delay?: boolean): void {
		if (!!this._cleanUpRef) {
			clearTimeout(this._cleanUpRef);
		}
		if (!!delay) {
			this._cleanUpRef = setTimeout(() => {
				this._viewContainerRef?.clear();
				this._cleanUpRef = null;
			}, 500);
		} else {
			this._viewContainerRef?.clear(); //Clear the content
		}
	}
}
